import React, { useRef } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Wrapper from '@/components/atoms/util/Wrapper'
import Container from '@/components/atoms/flex/Container'
import Headline from '@/components/atoms/util/Headline'
import H3Style from '@/components/atoms/typo/H3Style'
import ParagraphStyle from '@/components/atoms/typo/ParagraphStyle'
import SectionStyle from '@/components/atoms/util/SectionStyle'
import tw, { css, TwStyle } from 'twin.macro'
import isInView from '@/shared/isInView'

const ArticleStyle = (isView?: boolean, overWrite?: TwStyle) =>
  css(
    tw`mx-4 bg-white rounded-lg md:w-1/3 w-11/12 text-center lg:mt-20 mt-10 shadow-md p-5 relative duration-700`,
    isView ? tw`opacity-100 top-0` : tw`opacity-0 top-10`,
    overWrite && overWrite,
  )

const ImageContainerStyle = css(
  tw`w-14 absolute -left-5 -top-5 bg-white shadow-md rounded-full p-3`,
)

const Skills: React.FC = () => {
  const targetRef = useRef(null)
  const targetViewPos = isInView(targetRef)
  return (
    <section css={SectionStyle()}>
      <Wrapper>
        <Headline>Skills</Headline>
        <Container>
          <article css={ArticleStyle(targetViewPos)} ref={targetRef}>
            <div css={ImageContainerStyle}>
              <StaticImage
                src="../../../static/images/code.png"
                alt="code"
                placeholder="blurred"
              />
            </div>
            <h3 css={H3Style(tw`text-gray-700 mt-2`)}>web開発</h3>
            <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
              React.js / Gatsby.js /
              Next.jsを用いたモダンフロントエンドの設計&構築。
              <br />
              WordPressでのコーポレートサイト制作。
            </p>
          </article>
          <article css={ArticleStyle(targetViewPos, tw`delay-200`)}>
            <div css={ImageContainerStyle}>
              <StaticImage
                src="../../../static/images/backend.png"
                alt="code"
                placeholder="blurred"
              />
            </div>
            <h3 css={H3Style(tw`text-gray-700 mt-2`)}>バックエンド開発</h3>
            <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
              Node.js / Express / Firebaseを用いたバックエンド開発。
              <br />
              Google App Script / LineMessagingAPIを用いた簡易ツール開発。
            </p>
          </article>
          <article css={ArticleStyle(targetViewPos, tw`delay-300`)}>
            <div css={ImageContainerStyle}>
              <StaticImage
                src="../../../static/images/design.png"
                alt="code"
                placeholder="blurred"
              />
            </div>
            <h3 css={H3Style(tw`text-gray-700 mt-2`)}>デザイン</h3>
            <p css={ParagraphStyle(tw`text-gray-700 mt-2`)}>
              Adobe XD, Figmaを使ったwebデザイン制作。
              <br />
              Photoshopを使った名刺デザイン、フライヤーデザイン。バナーデザイン等。
            </p>
          </article>
        </Container>
      </Wrapper>
    </section>
  )
}

export default Skills

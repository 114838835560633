import React, { useState, useEffect } from 'react'

type TargetViewPosition = undefined | boolean

function isInView(targetRef: React.RefObject<HTMLElement>) {
  const [targetViewPosition, setTargetViewPosition] =
    useState<TargetViewPosition>(false)
  const isBrowser = typeof window !== 'undefined'
  if (isBrowser) {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTargetViewPosition(true) // 画面内に表示中
          return
        } else {
          setTargetViewPosition(false)
        }
      },
      {
        root: null,
        threshold: 0,
      },
    )
    useEffect(() => {
      // マウント時にobserverを登録
      if (targetRef.current) observer.observe(targetRef.current)

      // アンマウント時にobserverを解除
      return () => {
        observer.disconnect()
      }
    }, [])

    return targetViewPosition
  }
}

export default isInView

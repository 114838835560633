import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import tw, { css, styled } from 'twin.macro'
import CircleImage from '@/components/atoms/util/CircleImage'
import Wrapper from '@/components/atoms/util/Wrapper'
import Container from '@/components/atoms/flex/Container'
import ParagraphStyle from '@/components/atoms/typo/ParagraphStyle'

const Background = styled.section`
  height: 90vh;
  border-bottom-left-radius: 50% 11%;
  border-bottom-right-radius: 50% 11%;
  ${tw`md:pt-16 bg-gray-800 flex items-center shadow-xl min-height[700px] relative`}
`

const TextAreaStyle = css(tw`lg:w-4/6 md:w-1/2 w-full `)

const Mainvisual: React.FC = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: { name: { eq: "profile" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `).allFile.edges[0].node.childImageSharp.gatsbyImageData
  return (
    <Background>
      <Wrapper>
        <Container isReverse={true}>
          <div css={TextAreaStyle}>
            <h1 tw="lg:text-6xl md:text-4xl text-3xl md:text-left text-center text-white tracking-widest font-semibold whitespace-nowrap py-4">
              田中雄大
            </h1>
            <p css={ParagraphStyle(tw`text-white`)}>
              <a href="https://tamat.jp" target="_blank">
                合同会社TamaT
              </a>{' '}
              CEO
            </p>
            <p css={ParagraphStyle(tw`text-white`)}>
              フロント / サーバーサイドエンジニアとして
              {new Date().getFullYear() - 2017}年の開発経験。
              <br />
              開発環境の多くはNode.jsを用いた開発。
              <br />
              website制作、webapp開発経験多数。
            </p>
            <Container isCenter={true}></Container>
          </div>
          <CircleImage image={data} alt="test" />
        </Container>
        <Container>
          <Link
            tw="bg-red-500 text-white text-xl px-12 py-3 shadow-xl rounded-xl font-medium tracking-wider hover:bg-red-700 transition-duration[300ms] lg:mt-2 mt-6"
            to="/biography/"
          >
            check Bio
          </Link>
        </Container>
        <p tw="text-white animate-bounce absolute lg:bottom-10 bottom-6 text-3xl w-full text-center left-0">
          ↓
        </p>
      </Wrapper>
    </Background>
  )
}

export default Mainvisual

import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import tw from 'twin.macro'
import SectionStyle from '@/components/atoms/util/SectionStyle'
import Container from '@/components/atoms/flex/Container'
import Wrapper from '../atoms/util/Wrapper'
import PostCard from '../atoms/util/PostCard'

const RecentPosts: React.FC = () => {
  const postData = useStaticQuery(graphql`
    query RecentPostQuery {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 3
      ) {
        edges {
          node {
            frontmatter {
              date(formatString: "YYYY.MM.DD")
              title
              url
              description
              thumbnail {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `).allMarkdownRemark.edges.map((edge) => edge.node.frontmatter)
  return (
    <section
      css={SectionStyle(tw`bg-gray-800 lg:mt-0 mt-0 pt-10 lg:pb-12 pb-6`)}
    >
      <h2 tw="text-white md:text-3xl text-xl text-center font-semibold tracking-wider">
        Recent Posts
        <span tw="p-1 bg-red-500 inline-block rounded-full ml-1.5 relative">
          <span tw="p-1 bg-red-500 block rounded-full ml-1.5 animate-ping absolute top-0 -left-1.5"></span>
        </span>
      </h2>
      <Wrapper>
        <div tw="lg:py-12 py-6">
          <Container>
            {postData.map((post) => (
              <PostCard
                to={'/posts/' + post.url + '/'}
                image={post.thumbnail}
                title={post.title}
                date={post.date}
                description={post.description}
                key={post.title}
              />
            ))}
          </Container>
        </div>
        <Link
          to="/posts/"
          tw="block bg-red-500 hover:bg-red-700 duration-500 w-max py-3 px-12 mx-auto rounded-lg shadow-lg text-white tracking-widest text-base font-semibold"
        >
          記事一覧
        </Link>
      </Wrapper>
    </section>
  )
}

export default RecentPosts

import React from 'react'
import Mainvisual from '@/components/organisms/Mainvisual'
import Skills from '@/components/organisms/Skills'
import Works from '@/components/organisms/Works'
import RecentPosts from '@/components/organisms/RecentPosts'
import Contact from '../organisms/Contact'
import 'twin.macro'

const IndexPageLayout: React.FC = () => {
  return (
    <>
      <Mainvisual />
      <Skills />
      <Works />
      <svg
        tw="lg:-mt-36 -mt-10"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#1f2937"
          fillOpacity="1"
          d="M0,320L30,314.7C60,309,120,299,180,277.3C240,256,300,224,360,213.3C420,203,480,213,540,229.3C600,245,660,267,720,277.3C780,288,840,288,900,288C960,288,1020,288,1080,288C1140,288,1200,288,1260,272C1320,256,1380,224,1410,208L1440,192L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
        ></path>
      </svg>
      <RecentPosts />
      <svg
        tw="transform rotate-180 lg:-mb-32 -mb-10"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#1f2937"
          fillOpacity="1"
          d="M0,320L30,314.7C60,309,120,299,180,277.3C240,256,300,224,360,213.3C420,203,480,213,540,229.3C600,245,660,267,720,277.3C780,288,840,288,900,288C960,288,1020,288,1080,288C1140,288,1200,288,1260,272C1320,256,1380,224,1410,208L1440,192L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
        ></path>
      </svg>
      <Contact />
    </>
  )
}

export default IndexPageLayout

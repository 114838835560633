import React from 'react'
import { Link } from 'gatsby'
import { getImage, GatsbyImage, ImageDataLike } from 'gatsby-plugin-image'
import tw, { css } from 'twin.macro'
import ParagraphStyle from '@/components/atoms/typo/ParagraphStyle'
import { mediaQuery } from '@/shared/cssValues'
import H3Style from '@/components/atoms/typo/H3Style'

type CardProps = {
  to: string
  image: ImageDataLike
  title?: string
  description?: string
  date?: string
  label?: string
}

const CardStyle = css`
  ${mediaQuery({
    width: [
      '100%',
      'calc(50% - 16px)',
      'calc(33.333% - 16px)',
      'calc(33.333% - 16px)',
    ],
  })}
  ${tw`block bg-white shadow-md md:m-2 mt-2 rounded-lg transform overflow-hidden duration-500 hover:rotate-0`}
`

const cutDescription = (description: string) => {
  if (description.length >= 50) {
    let fixed = description.slice(0, 49)
    return fixed + '...'
  } else {
    return description
  }
}

const ImageStyle = css(tw`rounded-t-lg`)

const PostCard: React.FC<CardProps> = ({
  to,
  image,
  title,
  description,
  date,
}) => {
  const thumbnail = getImage(image)
  return (
    <Link
      to={to}
      // tw="bg-white pb-2 md:mx-3 mt-3 shadow-lg rounded-lg hover:scale-95 transform duration-500 w-full"
      css={CardStyle}
    >
      <GatsbyImage image={thumbnail} alt={title} css={ImageStyle} />
      <div tw="py-2 px-4">
        <h3 css={H3Style(tw`lg:text-xl`)}>{title}</h3>
        <p css={ParagraphStyle(tw`text-gray-500 pb-2`)}>{date}</p>
        <p css={ParagraphStyle(tw`text-gray-600 lg:text-base`)}>
          {cutDescription(description)}
        </p>
      </div>
    </Link>
  )
}

export default PostCard
